:root {
  --ion-font-family: 'Open Sans';
  --ion-tabbar-border-color-rgb: #fff;
  --readle-font-color-main-invert: #000;
  --ion-tabbar-background-color: #fff;
  --ion-label-font-size: 16px;
  --ion-safe-area-left: 0px;
  --ion-safe-area-right: 0px;

  --font-normal: 'Open Sans';
  --font-semibold: 'Open Sans Semibold';
  --font-bold: 'Open Sans Bold';

  //Spacing  
  --ion-xs-spacing: 5px;
  --ion-sm-spacing: 10px;
  --ion-md-spacing: 15px;
  --ion-lg-spacing: 20px;
  --ion-xl-spacing: 30px;

  // Colors
  --ion-color-primary: #6568BC;
  --ion-color-primary-light: #6568bc3a;
  --ion-color-primary-light-hover: #6568bc51;
  --ion-color-primary-darker: #5254a3;
  --ion-color-primary-rgb: rgb(101, 104, 188);
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #17a589;
  --ion-color-primary-tint: #31c3a6;
  --ion-color-secondary: #0089e5;
  --ion-color-secondary-rgb: 0, 137, 229;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0079ca;
  --ion-color-secondary-tint: #1a95e8;
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  --ion-color-success: #219EBC;
  --ion-color-success-rgb: 26, 188, 156;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #17a589;
  --ion-color-success-tint: #31c3a6;
  --ion-color-warning: #ffa400;
  --ion-color-warning-rgb: 255, 164, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e09000;
  --ion-color-warning-tint: #ffad1a;
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-A1: #E6F4E3;
  --ion-color-A2: #EEF9DD;
  --ion-color-B1: #E0EDF2;
  --ion-color-B2: #CDEDF4;
  --ion-color-C1: #E4D6FC;
  --ion-color-C2: #be9ef4;
  --ion-color-accept: #409519;

  /*
      Custom Readle Variables
    */

  --readle-font-color-main: #000000;
  --readle-font-color-light: #595959;

  --readle-header-background: #F6F6FA;
  --readle-content-background: #F6F6FA;
  --card-background: #fff;

  --readle-card-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  --readle-card-background: #ffffff;
  --readle-divider-color: #eeeeee;
  --readle-list-wrapper-background: #eeeeee;

  --readle-tabbar-background: #fff;
  --readle-tabbar-color: #666666;
  --readle-tabbar-shadow: 0px -12px 42px -1px rgba(0, 0, 0, 0.1);

  --readle-level-filter-background: #eeeeee;
  --readle-level-filter-color: #000000;

  --readle-level-background-a: #D0ECF6;
  --readle-level-active-color-a: #45a5c7;
  --readle-level-background-b: #E8E7F7;
  --readle-level-active-color-b: #807ad2;
  --readle-level-background-c: #FAEBE9;
  --readle-level-active-color-c: #ff6f5c;

  --readle-shop-select-background: #f4f5f8;
  --readle-shop-select-color: #353535;

  --readle-training-cta-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  --readle-trianing-today-color: #57A773;
  --readle-trianing-today-background: #57A7732e;
  --readle-trianing-hard-color: #FB8500;
  --readle-trianing-hard-background: #FB85002e;
  --readle-trianing-okay-color: #023047;
  --readle-trianing-okay-background: #0230472e;
  --readle-trianing-easy-color: #FFB703;
  --readle-trianing-easy-background: #FFB7032e;
  --readle-trianing-done-color: #57A773;
  --readle-trianing-done-background: #57A7732e;
  --readle-training-stackcounter-background: rgba(0, 0, 0, 0.07);
  --readle-profile-card-background: #eeeeee;
  --readle-story-tab-background: #f5f5f5;

  --level-filter-font-color-default: #000;
  --card-action-btn: #e7e7e7;
  --story-background-main: #F6F6FA;
  --story-background-secondary: #ffffff;
  --story-audiobar-background: rgba(255, 255, 255, 0.65);
  --keyword-action-background: #EDEBF4;

  --training-font-color-again: #66597a;
  --training-font-color-hard: #7a5959;
  --training-font-color-okay: #5c7984;
  --training-font-color-easy: #7aa271;

  --training-background-easy: #E6F4E3;
  --training-background-okay: #E0EDF2;
  --training-background-hard: #ffdede;
  --training-background-again: #E4D6FC;

  --quiz-answer-color: var(--ion-color-primary);
  --quiz-answer-border-color: var(--ion-color-primary);
  --quiz-answer-background-color: rgba(255, 255, 255, 0);

  --story-level-color-a: black;
  --story-level-background-a: #E6F4E3;
  --story-level-color-b: black;
  --story-level-background-b: #E0EDF2;
  --story-level-color-c: black;
  --story-level-background-c: #E4D6FC;
  --keyword-translation-color: var(--readle-font-color-light);

  --shop-option-background-color: #fff;
  --shop-title-highlight-color: #000;
  --shop-title-highlight-background: #e6f4e3;
  --shop-back-btn-color: #626262;
  --shop-back-btn-background: #fff;

  --word-detail-title-background: #f9f9f9;
  --word-title-font-color: var(--ion-color-primary);

  --sencence-word-background: #fff;

  --word-detail-persona-font-color: var(--readle-font-color-light);
  --sidebar-hover-background: hwb(238deg 40% 26% / 15%);

  //web app article spacing 
  --webapp-width: 55%;

  --revision-background: #ECEDF7;

  --tracker-least-color: #CBCEEF;
  --tracker-middle-color: #8F95D1;
  --tracker-most-color: #424DAC;

  --light-primary-color: #dcddef;
  --light-primary-color-hover: #c1c2e3;
  --font-size-mult: 2px;
  --furigana-space: 1;

  --purchase-shop-tint: #7577c2;

  --quiz-percentage: 0;
  --font-grey: #696870;
  --translation-font: #8F92CF;
  --disabled-btn-color: #6467b926;
}


body.dark {

  --ion-color-A1: #cef4c5;
  --ion-color-A2: #e0f5c0;
  --ion-color-B1: #bee1ef;
  --ion-color-B2: #b3e9f5;
  --ion-color-C1: #cbb4f4;

  --ion-color-primary: #6467BB;
  --ion-color-primary-rgb: rgb(101, 104, 188);
  --ion-color-primary-light: #6568bc3a;

  --readle-font-color-main: #ffffff;
  --readle-font-color-main-invert: #000;
  --readle-font-color-light: #a2a2a2;

  --readle-header-background: #111111;
  --readle-content-background: #111111;
  --readle-card-shadow: none;
  --card-background: #202020;


  --readle-divider-color: #222222;
  --readle-list-wrapper-background: #303030;

  --readle-tabbar-background: #202020;
  --readle-tabbar-color: #ffffff;
  --readle-tabbar-shadow: none;

  --readle-level-filter-background: rgba(238, 238, 238, 0.11);
  --readle-level-filter-color: #939393;

  /*
    --readle-level-background-a: rgba(24, 168, 139, 0.36);
    --readle-level-active-color-a: #219EBC;
    --readle-level-background-b: rgba(128, 151, 255, 0.36);
    --readle-level-active-color-b: #FFB703;
    --readle-level-background-c: rgba(255, 151, 128, 0.36);
    --readle-level-active-color-c: #FB8500;
    */

  --readle-level-background-a: #57A773;
  --readle-level-active-color-a: #fff;
  --readle-level-background-b: #FFB703;
  --readle-level-active-color-b: #fff;
  --readle-level-background-c: #FB8500;
  --readle-level-active-color-c: #fff;

  --readle-shop-select-background: #303030;
  --readle-shop-select-color: #a2a2a2;

  --readle-training-cta-shadow: none;
  --readle-trianing-today-color: #8097ff;
  --readle-trianing-today-background: #8097ff2e;
  --readle-trianing-hard-color: #FB8500;
  --readle-trianing-hard-background: #FB85002e;
  --readle-trianing-okay-color: #00a9fd;
  --readle-trianing-okay-background: #00496e59;
  --readle-trianing-easy-color: #FFB703;
  --readle-trianing-easy-background: #FFB7032e;
  --readle-trianing-done-color: #8097ff;
  --readle-trianing-done-background: #8097ff2e;
  --readle-training-stackcounter-background: rgba(255, 255, 255, 0.15);

  --readle-profile-card-background: #303030;
  --readle-story-tab-background: #222222;

  --level-filter-font-color-default: #a2a2a2;
  --card-action-btn: #595959;
  --story-background-main: #000000;
  --story-background-secondary: #181818;
  --story-audiobar-background: rgba(255, 255, 255, 0.1);
  --keyword-action-background: #191919;

  --training-font-color-hard: #b15d5d;
  --training-font-color-again: #7e5db1;
  --training-font-color-okay: #4b99b7;
  --training-font-color-easy: #65ad55;

  --training-background-easy: #a6d39c29;
  --training-background-okay: #5998b12b;
  --training-background-again: #66597a24;
  --training-background-hard: #b9737324;

  --quiz-answer-color: #fff;
  --quiz-answer-border-color: var(--ion-color-primary);
  --quiz-answer-background-color: #171717;

  --story-level-color-a: #E6F4E3;
  --story-level-background-a: #e6f4e31f;
  --story-level-color-b: #E0EDF2;
  --story-level-background-b: #E0EDF21f;
  --story-level-color-c: #E4D6FC;
  --story-level-background-c: #E4D6FC1f;
  --keyword-translation-color: #fff;

  --shop-option-background-color: #262626;
  --shop-title-highlight-color: #ff9000;
  --shop-title-highlight-background: #e6f4e31f;
  --shop-back-btn-color: #e1e1e1;
  --shop-back-btn-background: #2e2e2e;

  --word-detail-title-background: #f9f9f924;
  --word-detail-title-font-color: #fff;

  --sencence-word-background: #2f2f2f;
  --word-detail-persona-font-color: rgb(236, 236, 236);
  --sidebar-hover-background: hwb(238deg 40% 26% / 40%);

  --font-grey: #d3d3d3;
  --translation-font: #bfc2ff;

}
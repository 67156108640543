/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import 'animate.css/animate.min.css';

:focus {
  outline: none;
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('assets/fonts/Open_Sans/OpenSans-Bold.woff2') format('woff2'),
    url('assets/fonts/Open_Sans/OpenSans-Bold.woff') format('woff'),
    url('assets/fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('assets/fonts/Open_Sans/OpenSans-Regular.woff2') format('woff2'),
    url('assets/fonts/Open_Sans/OpenSans-Regular.woff') format('woff'),
    url('assets/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('assets/fonts/Open_Sans/OpenSans-SemiBold.woff2') format('woff2'),
    url('assets/fonts/Open_Sans/OpenSans-SemiBold.woff') format('woff'),
    url('assets/fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Open Sans' !important;
  background-color: var(--readle-content-background);
}

ion-skeleton-text {
  --border-radius: 10px;
}

// ion-router-outlet {
//   height: 105%;
// }

pre {
  font-family: 'Open Sans' !important;
}

ion-button {
  width: 100%;
  --color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: none;
}

app-home {
  width: 100% !important;
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-text-color-step-800, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #0054e9);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);

  max-width: 100vw;
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.hide {
  display: none !important;
}

.show {
  display: block;
}

.karte {
  --ion-safe-area-left: 0px;
  --ion-safe-area-right: 0px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: block;
  position: relative;
  background: var(--background);
  color: var(--color);
  overflow: hidden;
  --background: var(--readle-card-background);
  --color: var(--ion-text-color-step-400, #666666);
  margin: var(--ion-xs-spacing) 0 var(--ion-lg-spacing) 0;
  border-radius: 8px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  font-size: 14px;
}

.premium_lock {
  width: 16px !important;
  display: inline !important;
}

.characterSpeech {
  .currentBlock {

    .word-active,
    .word-active-sentence {
      color: var(--ion-color-primary) !important;
      background: var(--ion-color-light) !important;
    }
  }
}

.word-active {
  background: var(--ion-color-primary);
  color: var(--ion-color-light) !important;
  display: inline-block;
  //transform: scale(1.1);
  border-radius: 5px !important;
  //box-shadow: 0 4px 16px rgba(0, 0, 0, .2);
  transition: transform 0.2s;

  &.webapp-color {
    background: #DDDDFB !important;
    ;
    color: black !important;
  }
}

.word-active-sentence {
  background: var(--ion-color-primary);
  color: var(--ion-color-light) !important;
  display: inline-block;
  //border-radius: 5px;
  //box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;

  // margin-right: 0px !important;
  // padding-right: 4px !important;
  &.webapp-color {
    background: #DDDDFB !important;
    ;
    color: black !important;
  }
}

#animation-container {
  transform: scale(2.1);
}

.lock {
  float: left;
  padding-top: 3px;
  padding-right: 5px;
}

.lock-right {
  //float: right;
  padding-top: 3px;
  //padding-right: 15px;
  margin-left: -40px;
}

app-rate {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.blurWord {
  filter: blur(4px);
}

.tabs-inner {
  position: unset !important;
}

.A1,
.A2,
.B1,
.B2,
.C1,
.C2,
.N1,
.N2,
.N3,
.N4,
.N5,
.HSK1,
.HSK2,
.HSK3,
.HSK4,
.HSK5,
.HSK6 {
  color: var(--readle-font-color-main) !important;
  font-weight: 600;
}

.A1,
.N5,
.HSK1 {
  background-color: var(--ion-color-A1) !important;
}

.A2,
.N4,
.HSK2 {
  background-color: var(--ion-color-A2) !important;
}

.B1,
.N3,
.HSK3 {
  background-color: var(--ion-color-B1) !important;
}

.B2,
.N2,
.HSK4 {
  background-color: var(--ion-color-B2) !important;
}

.C1,
.N1,
.HSK5 {
  background-color: var(--ion-color-C1) !important;
}

.C2,
.HSK6 {
  background-color: var(--ion-color-C2) !important;
}

.modal-wrapper {
  border-radius: 20px !important;
  // background: var(--readle-content-background) !important;
  max-width: 500px;
}

.my-custom-class {
  --border-radius: 30px;
}

.storySettings {
  .pane {
    padding: 20px;
    background: var(--readle-content-background);
    border: 1px solid #fff;
    color: var(--readle-font-color-main);
  }
}

.shop-modal-web {
  --min-height: 680px;
  --backdrop-opacity: 0.9;
}

.login-modal {
  --min-height: 95%;
  --min-width: 60%;
  --backdrop-opacity: 0.9;
}

.shop-modal {
  @media (min-height: 1000px) {
    .modal-wrapper {
      max-height: 700px;
    }
  }
}

@media (min-width: 768px) {
  .shop-modal {
    .modal-shadow {
      box-shadow: none !important;
    }

    .modal-wrapper {
      background: transparent !important;
      width: 570px;
      height: 750px;
    }
  }
}

@media (max-height: 700px) {
  .shop-modal-web {
    --min-height: 95%;
  }
}

app-shop,
.modal-wrapper {
  background-color: var(--readle-content-background) !important;
}

.alert-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

/*
.alert-wrapper {
  padding: 25px;
  top: 50%;
  position: fixed;
  left: 25px;
  right: 25px;
  z-index: 100;
  transform: translateY(-50%);
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 25px;
  z-index: 1001;
  text-align: center;
}*/

.list-wrapper {
  background: var(--readle-list-wrapper-background);
  padding: 0px;
  padding-right: 0px;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 15px;
  overflow: hidden;
}

settings-list {
  justify-content: unset !important;
}

.level-bubble {
  font-size: 16px;
  background: var(--card-background);
  width: 50px;
  text-align: center;
  padding: 8px 0px !important;
  color: var(--level-filter-font-color-default);
  margin-right: 10px;
  border-radius: 10px;
  font-weight: bold;
  flex: 1 1 auto;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  font-family: var(--font-normal);

  &.level-all {
    min-width: 100px;
  }

  &.level-all.selected {
    color: #fff;
    background-color: var(--ion-color-primary);
  }

  &.level-HSK1,
  &.level-HSK2,
  &.level-HSK3,
  &.level-HSK4 {
    font-size: 0.85em;
  }

  &.level-A1.selected,
  &.level-N5.selected,
  &.level-HSK1.selected {
    background-color: var(--ion-color-A1);
    color: black;
  }

  &.level-A2.selected,
  &.level-N4.selected,
  &.level-HSK2.selected {
    background-color: var(--ion-color-A2);
    color: black;
  }

  &.level-B1.selected,
  &.level-N3.selected,
  &.level-HSK3.selected {
    background-color: var(--ion-color-B1);
    color: black;
  }

  &.level-B2.selected,
  &.level-N2.selected,
  &.level-HSK4.selected {
    background-color: var(--ion-color-B2);
    color: black;
  }

  &.level-C1.selected {
    background-color: var(--ion-color-C1);
    color: black;
  }

  &.level-C2.selected {
    background-color: var(--ion-color-C2);
    color: black;
  }


  &:last-of-type {
    margin-right: 0px !important;
  }

  // &.selected {
  //   &:after {
  //     display: block;
  //     content: '';
  //     height: 12px;
  //     width: 12px;
  //     background-color: var(--ion-color-primary);
  //     border-radius: 50%;
  //     margin-top: 5px;
  //     margin-left: auto;
  //     margin-right: auto;
  //   }
  // }
}

/* 
ion-content::part(scroll) {
  background-color: #fff;
} */

.grammar-example {
  font-style: italic;
  margin-top: 5px !important;
}

.custom-alert {
  --background: var(--readle-content-background);
  --color: var(--readle-font-color-main);

  .alert-wrapper {
    .alert-head {

      // header styling here eg. background
      h2 {
        color: var(--readle-font-color-main);
      }
    }

    .alert-message {
      color: var(--readle-font-color-main)
    }
  }
}

.grammar_example {
  table {
    width: 100%;
    margin-bottom: 20px;

    th {
      border-bottom: 1px solid var(--readle-divider-color);
      text-align: left;
    }

    th,
    td {
      border-right: 1px solid var(--readle-divider-color);
      padding-left: 10px;

      &:last-of-type {
        border-right: unset;
      }

      &:first-of-type {
        padding-left: unset;
      }
    }


  }
}

.cupertino-pane-wrapper {
  --cupertino-pane-background: #F6F6FA;
}

.cupertino-pane {
  background-color: #F6F6FA;
  margin-bottom: 20px;

  .pane {
    background-color: #F6F6FA;
  }
}

.highlight {
  font-weight: 700;
}

.highlight-purple,
.purple,
.green-highlight {
  color: var(--ion-color-primary);
}

.purple,
.green-highlight {
  font-weight: bold;
}

.highlight-blue {
  color: #DAFBFD;
}

.panel-title .plan-title b {
  color: #fff;
}

.highlight-yellow-gradient {
  background: -webkit-linear-gradient(324deg, #FFB904, #ff6b01);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bulletin-pane,
.bulletin-pane-trial {

  outline: 3px solid red;

  .pane {
    border-radius: 20px !important;
    width: calc(100% - 40px) !important;
    margin: auto;
    left: 0;
    right: 0;
    height: auto !important;
  }

}

.loginAppleAlert {
  z-index: 999999999 !important;
}

.cupertino-pane-training-sentence-wrapper {

  .pane {
    padding: 20px;
  }

  &.cupertino-pane-training-sentence-wrapper {
    .pane {
      background: var(--readle-content-background);
      border: 1px solid #fff;
      color: var(--readle-font-color-main);
    }
  }
}

.owl-carousel.owl-theme.owl-loaded {
  padding: 0 20px;
}

.owl-carousel .owl-item {
  width: 200px !important;
}

.books {
  .owl-carousel .owl-item {
    width: 140px !important;
  }
}

.owl-carousel .owl-stage {
  width: max-content !important;
}

.owl-theme .owl-nav .owl-next,
.owl-theme .owl-nav .owl-prev {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 3;
  top: 90px;
  background: #F6F6FA !important;
  border: 1px solid #A9A9B8;
  border-radius: 24px !important;
  text-align: center;
  color: var(--readle-font-color-main) !important;
  font-family: monospace;
  font-size: 21px;
  padding-top: 5px;
}

.owl-theme .owl-nav .owl-next {
  right: 0;
}

.owl-carousel .owl-prev {
  left: 0;
}

.owl-theme .owl-nav .disabled {
  display: none;
}

.share-img {
  width: 25px;
  position: relative;
  top: 6px;
}

.add-home {
  background-color: var(--ion-color-primary-light);
  border-radius: 5px;
  margin: 5px 0;
  padding: 0 3px;
}
